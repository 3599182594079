import React, { useState } from "react";
import { campaignClient } from "../../api";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import "./index.css";
import { useStores } from "../../stores";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { PageLoader } from "..";
import dayjs from "dayjs";

const SearchKeyword = () => {
  const [word, setWord] = useState("");
  const user = useStores().authStore.user;

  const storeKeyword = useStores().keywordStore;

  const { isLoading: loadingKeywords, data: keywords } = useQuery(["getKeywords", {}], async () => {
    const { keywords }: { keywords: MainDbReturnTypes.Keyword[] } = await campaignClient.call(
      "getKeywords",
      {}
    );
    if (user.profile === "shipping") {
      return keywords
        .filter((k) => k.dynamicSettings?.shipping?.email === user.name)
        .sort(function (a, b) {
          return a.word.localeCompare(b.word);
        });
    } else {
      return keywords
        .filter((k) => dayjs().diff(dayjs(k.startTime), "years", true) < 3)
        .sort(function (a, b) {
          return a.word.localeCompare(b.word);
        });
    }
  });

  const navigate = useNavigate();

  if (loadingKeywords) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Keyword</h2>
        <p>Please select a keyword to continue.</p>
      </div>
      <div className="main__body">
        <div className="form-search">
          <label> Keyword</label>
          <select
            value={word}
            onChange={(e) => {
              setWord(e.target.value);
            }}
            className="search-input field search_field">
            <option value="">Please select a key word. </option>
            {keywords && keywords.length && (
              <>
                {keywords.map((keyword) => (
                  <option value={keyword.keywordKey} key={keyword.keywordKey}>
                    {`${keyword.word} - ${new Date(keyword.startTime).toLocaleDateString()}`}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>

        <button
          disabled={!word ? true : false}
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            let keywordSelected = keywords!.find((k) => k.keywordKey === word);

            if (keywordSelected) {
              storeKeyword.setKeyword({
                word: keywordSelected.word,
                keywordKey: keywordSelected.keywordKey,
                shipping:
                  keywordSelected.dynamicSettings.shipping &&
                  keywordSelected.dynamicSettings.shipping,
                contestType: keywordSelected.basicSettings.contestType || "",
                dynamicSettings:keywordSelected.dynamicSettings
              });
            }
            navigate(window.location.pathname);
          }}>
          Submit
        </button>
      </div>
    </>
  );
};

export default SearchKeyword;
